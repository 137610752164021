import './MortgageAutomator.css';
import IndividualAPI from '../../components/IndividualAPI';
import NavBar from '../../components/NavBar';
import MortgageAutomatorLogo from '../../images/MortgageAutomatorLogo.png'

const MortgageAutomator = () => {
    return (
    <div className='MortgageAutomatorContainer'>
        {/* Import Navbar */}
        <NavBar/>
        {/* Import Individual API page */}
        <IndividualAPI 
            NameOfAPI={"Mortgage Automator"}
            APIlogo={MortgageAutomatorLogo}
        />
    </div>
    )
}

export default MortgageAutomator;