import './FourConveyerBelts.css';
import './ThumbnailLink.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import AboveConveyer from '../images/AboveConveyer.png';
import Tasks from '../images/tasks.png';
import PreFileThumbnail from '../images/PreFileThumbnail.png'
import ThumbnailLink from './ThumbnailLink';

const ThreeConveyerBelts = () => {
  return (
    <>
        <div className="OurConveyerBeltsContainer">
          <div className="timeBoundContainer">
            <div className="taskImgContainerLastTask">
              <ThumbnailLink
                to="/Tasks"
                className="thumbnailLinkTasksContainer2" 
                thumbnail={PreFileThumbnail}
                id="thumbnailStages2"
              >
                <img src={Tasks} alt="" className='taskIconLastTask'/>
                <p className='taskText'>Time Bound Tasks</p>
              </ThumbnailLink>
            </div>
          </div>
            <div className="titleDescriptionConveyerBeltsContainer">
              <div className="titleConveyerBeltsContainer">
                <p className='titleConveyerBelts'><span className='boldTitles'>Digital Conveyor Belts: </span>Powering File Stages and Smart Tasks</p>
              </div>
              <div className="descriptionConveyerBeltsContainer">
                <p className='descriptionConveyerBelts'>
                </p>
              </div>
            </div>
            <div className="firstFloorContainer">
              <div className="leftConveyerBelt"></div>
              <div className="rightConveyerBelt"></div>
              
              {/* Stage Bound Tasks */}
              <div className="topRectangle">  
                <FontAwesomeIcon icon={faGear} className="gearTopConveyerBelts"/>
                <div className="taskImgContainer">
                  <ThumbnailLink
                    to="/Tasks"
                    className="thumbnailLinkTasksContainer" 
                    thumbnail={PreFileThumbnail}
                    id="thumbnailStages"
                  >
                    <img src={Tasks} alt="" className='taskIcon'/>
                    <p className='taskText'>Stage Bound Tasks</p>
                  </ThumbnailLink>
                </div>
                <FontAwesomeIcon icon={faGear} className="gearTopConveyerBelts"/>
              </div>

              {/* Stage Advance Tasks */}
              <div className="middleRectangle">
                <FontAwesomeIcon icon={faGear} className="gearTopConveyerBelts"/>
                <div className="taskImgContainer">
                  <ThumbnailLink
                    to="/Tasks"
                    className="thumbnailLinkTasksContainer" 
                    thumbnail={PreFileThumbnail}
                    id="thumbnailStages"
                  >
                    <img src={Tasks} alt="" className='taskIcon'/>
                    <p className='taskText'>Stage Advance Tasks</p>
                  </ThumbnailLink>
                  
                </div>
                <FontAwesomeIcon icon={faGear} className="gearTopConveyerBelts"/>
              </div>
              <div className="bottomRectangle"></div>
            </div>
            <div className="AboveConveyerImgContainer">          
                <img className='AboveConveyerImg' src={AboveConveyer} alt=""/>
                {/* Begin Stages Buttons */}

                {/* Pre-File Stage */}
                <div className="stagesButtonConveyerBeltContainer">
                  <ThumbnailLink
                    to="/PreFile"
                    className="stagesButtonConveyerBelt" 
                    thumbnail={PreFileThumbnail}
                    id="thumbnail"
                  >
                  </ThumbnailLink>
                  {/* <Link to="/PreFile" className='stagesButtonConveyerBelt'/> */}
                </div>

                {/* File Opening Stage */}
                <div className="stagesButtonConveyerBeltContainer">
                  <ThumbnailLink
                    to="/FileOpening"
                    className="stagesButtonConveyerBelt" 
                    thumbnail={PreFileThumbnail}
                  >
                  </ThumbnailLink>
                </div>

                {/* Necessary Info Stage */}
                <div className="stagesButtonConveyerBeltContainer">
                  <ThumbnailLink
                    to="/NecessaryInfo"
                    className="stagesButtonConveyerBelt" 
                    thumbnail={PreFileThumbnail}
                  >
                  </ThumbnailLink>
                </div>

                {/* File Review Stage */}
                <div className="stagesButtonConveyerBeltContainer">
                  <ThumbnailLink
                    to="/FileReview"
                    className="stagesButtonConveyerBelt" 
                    thumbnail={PreFileThumbnail}
                  >
                  </ThumbnailLink>
                </div>

                {/* Document Production Stage */}
                <div className="stagesButtonConveyerBeltContainer">
                  <ThumbnailLink
                    to="/DocumentProduction"
                    className="stagesButtonConveyerBelt" 
                    thumbnail={PreFileThumbnail}
                  >
                  </ThumbnailLink>
                </div>

                {/* Document Signing Stage */}
                <div className="stagesButtonConveyerBeltContainer">
                  <ThumbnailLink
                    to="/DocumentSigning"
                    className="stagesButtonConveyerBelt" 
                    thumbnail={PreFileThumbnail}
                  >
                  </ThumbnailLink>
                </div>

                {/* Post Signing Stage */}
                <div className="stagesButtonConveyerBeltContainer">
                  <ThumbnailLink
                    to="/PostSigning"
                    className="stagesButtonConveyerBelt" 
                    thumbnail={PreFileThumbnail}
                  >
                  </ThumbnailLink>
                </div>

                {/* Closing Stage */}
                <div className="stagesButtonConveyerBeltContainer">
                  <ThumbnailLink
                    to="/Closing"
                    className="stagesButtonConveyerBelt" 
                    thumbnail={PreFileThumbnail}
                  >
                  </ThumbnailLink>
                </div>

                {/* Reporting Stage */}
                <div className="stagesButtonConveyerBeltContainer">
                  <ThumbnailLink
                    to="/Reporting"
                    className="stagesButtonConveyerBelt" 
                    thumbnail={PreFileThumbnail}
                  >
                  </ThumbnailLink>
                </div>
            </div>
      </div>
    </>
  )
}

export default ThreeConveyerBelts