import './FiveHumanInteraction.css';
import './ThumbnailLink.css';
import ThumbnailLink from './ThumbnailLink';
import HumanInteraction from '../images/HumanInteraction.png';
import VelocityThumbnail from '../images/VelocityThumbnail.png';

const FourHumanInteraction = () => {
  return (
    <div className="HumanInteractionContainer">
        <div className="humanInteractionContainer">
            <div className="desktopLaptopIconContainer">
              <img src={HumanInteraction} alt="" className="desktopLaptopIcon"/>

              {/* Clients */}
          <div className="LineClients">
            <ThumbnailLink 
              to="/Clients" 
              className="LinksThatUseThumbnail" 
              thumbnail={VelocityThumbnail}
            >
              Clients
            </ThumbnailLink>
          </div>

          {/* Referals */}
          <div className="LineReferals">
            <ThumbnailLink
              to="/Referals"
              className="LinksThatUseThumbnail"
              thumbnail={VelocityThumbnail}
            >
              Referals
            </ThumbnailLink>
          </div>

          {/* Internal Users */}
          <div className="LineInternalUsers">
            <ThumbnailLink
              to="/InternalUsers"
              className="LinksThatUseThumbnail"
              thumbnail={VelocityThumbnail}
            >
              Internal Users
            </ThumbnailLink>
          </div>

          {/* Funders */}
          <div className="LineFunders">
            <ThumbnailLink
              to="/Funders"
              className="LinksThatUseThumbnail"
              thumbnail={VelocityThumbnail}
            >
              Funders
            </ThumbnailLink>
          </div>

          {/* Signers */}
          <div className="LineSigners">
            <ThumbnailLink
              to="/Signers"
              className="LinksThatUseThumbnail"
              thumbnail={VelocityThumbnail}
            >
              Signers
            </ThumbnailLink>
          </div>

          {/* Peripherals Users */}
          <div className="LinePeripheralsUsers">
            <ThumbnailLink
              to="/PeripheralsUsers"
              className="LinksThatUseThumbnail"
              thumbnail={VelocityThumbnail}
            >
              Peripherals Users
            </ThumbnailLink>
          </div>

            </div>
            <div className="titleDescriptionHumanInteractionContainer">
              <div className="titleHumanInteractionContainer">
                <p className='titleHumanInteraction'><span className='boldTitles'>Human Interaction: </span><br />Adding Value to Automation</p>
              </div>
              <div className="descriptionHumanInteractionContainer">
                <p className='descriptionHumanInteraction'>
                Transaction automation in a professional services business provides tremendous efficiency gains,  
                but can also come across as “cold” to many clients.
                <br />
                <br />
                In nexSEMBLY, valuable human input has not been overlooked or removed. In fact, 
                humans interact with the platform in a variety of ways, each of which adds value to the overall user and client experience.
                </p>
              </div>
            </div>

        </div>
    </div>
  )
}

export default FourHumanInteraction;