import './ThreeInterfaces.css';
import './ThumbnailLink.css';
import ThumbnailLink from './ThumbnailLink';
import API from '../images/API.png';
import VelocityThumbnail from '../images/VelocityThumbnail.png';
import MortgageAutomatorThumbnail from '../images/MortgageAutomatorThumbnail.png';
import NexOneThumbnail from '../images/NexOneThumbnail.png';
import PaybiltThumbnail from '../images/PaybiltThumbnail.png';
import eIDMeThumbnail from '../images/eIDMEThumbnail.png';
import ChicagoTitleThumbnail from '../images/ChicagoTitleThumbnail.png';
import TeraViewThumbnail from '../images/TeraViewThumbnail.png';

const TwoInterfaces = () => {
  return (
    <>
      <div className="OurJunctionsContainer">
        <div className="APIAndNumbersContainer">
          <img src={API} alt="" className="APIIconJunctions" />
          
          {/* Velocity API */}
          <div className="LineVelocity">
            <ThumbnailLink 
              to="/VelocityAPI" 
              className="LinksThatUseThumbnail" 
              thumbnail={VelocityThumbnail}
            >
              Velocity
            </ThumbnailLink>
          </div>

          {/* Mortgage Automator API */}
          <div className="LineMortgageAutomator">
            <ThumbnailLink
              to="/MortgageAutomatorAPI"
              className="LinksThatUseThumbnail"
              thumbnail={MortgageAutomatorThumbnail}
            >
              Mortgage Automator
            </ThumbnailLink>
          </div>

          {/* NexOne API */}
          <div className="LineNexOne">
            <ThumbnailLink
              to="/NexOneAPI"
              className="LinksThatUseThumbnail"
              thumbnail={NexOneThumbnail}
            >
              NexOne
            </ThumbnailLink>
          </div>

          {/* Payblit API */}
          <div className="LinePaybilt">
            <ThumbnailLink
              to="/PaybiltAPI"
              className="LinksThatUseThumbnail"
              thumbnail={PaybiltThumbnail}
            >
              Paybilt
            </ThumbnailLink>
          </div>

          {/* eID-Me API */}
          <div className="LineeIDMe">
            <ThumbnailLink
              to="/eIDMeAPI"
              className="LinksThatUseThumbnail"
              thumbnail={eIDMeThumbnail}
            >
              eID-Me
            </ThumbnailLink>
          </div>

          {/* ChicagoTitle API */}
          <div className="LineChicagoTitle">
            <ThumbnailLink
              to="/ChicagoTitleAPI"
              className="LinksThatUseThumbnail"
              thumbnail={ChicagoTitleThumbnail}
            >
              Chicago Title
            </ThumbnailLink>
          </div>

          {/* Tera View API */}
          <div className="LineTeraView">
            <ThumbnailLink
              to="/TeraViewAPI"
              className="LinksThatUseThumbnail"
              thumbnail={TeraViewThumbnail}
            >
              Teraview
            </ThumbnailLink>
          </div>


        </div>
        <div className="titleDescriptionAPIContainer">
          <div className="titleOurJunctionsContainer">
            <p className="titleOurJunctions">
              <span className="boldTitles">Interfaces:</span> <br />Data Collected from Anywhere
              Online
            </p>
          </div>
          <div className="descriptionOurJunctionsContainer">
            <p className="descriptionOurJunctions">
              Relevant client and transaction data may also exist in external connectable databases.
              Through nexSEMBLY’s Application Protocol Interfaces (API’s), this data can be securely
              linked into the platform.
              <br />
              <br />
              Seamless data flows in and out of the platform, allow for tremendous accuracy and
              efficiency gains.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TwoInterfaces;