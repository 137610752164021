import './Paybilt.css';
import IndividualAPI from '../../components/IndividualAPI';
import NavBar from '../../components/NavBar';
import PaybiltLogo from '../../images/PaybiltLogo.jpg'

const Paybilt = () => {
    return (
    <div className='PaybiltContainer'>
        {/* Import Navbar */}
        <NavBar/>
        {/* Import Individual API page */}
        <IndividualAPI 
            NameOfAPI={"Paybilt"}
            APIlogo={PaybiltLogo}
        />
    </div>
    )
}

export default Paybilt;