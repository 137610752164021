import '../../App.css';
import IndividualStages from '../../components/IndividualStages';
import NavBar from '../../components/NavBar';

const DocumentProduction = () => {
    return (
    <div className='StagesCSSContainer'>
        {/* Import Navbar */}
        <NavBar/>
        {/* Import Individual Stage page */}
        <IndividualStages
            NameOfStage={"Document Production"}
        />
    </div>
    )
}

export default DocumentProduction;