import './IndividualAPI.css';
import { Container, Row, Col, Card } from 'react-bootstrap';
import WorkingStation1 from '../images/NewsRight1.jpg';
import WorkingStation2 from '../images/NewsRight2.jpg';
import WorkingStation3 from '../images/NewsRight3.jpg';

const IndividualAPI = ( {NameOfAPI, APIlogo} ) => {
    return (
    <div className='IndividualAPIContainer'>
        <Container>
            <p className='nameOfIndividualAPI'>{NameOfAPI}</p>
            <Row>
                <Col xxl={4} xl={4} lg={4} md={4} sm={12}>
                    <Card className='cardContainerAboutAPI'>
                        <Card.Body>
                            <Card.Title className="cardTextTitleContainer">About {NameOfAPI}</Card.Title>
                            <Card.Text className="cardTextContainer">
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus, impedit consequatur distinctio odio 
                                dignissimos corrupti?
                            </Card.Text>
                        </Card.Body>
                        <Card.Img variant="top" src={APIlogo} className='cardIndividualImgContainer' />
                        <Card.Body>
                            <p className='undertextIndividual'>Visit {NameOfAPI}</p>
                        </Card.Body>
                    </Card>
                </Col>

                <Col xxl={4} xl={4} lg={4} md={4} sm={12}>
                    <Card className='cardContainerAboutAPI'>
                        {/* <Card.Img variant="top" src={APIlogo} className='cardIndividualImgContainer' /> */}
                        <Card.Body>
                            <Card.Title className="cardTextTitleContainer">nexSembly and {NameOfAPI}</Card.Title>
                            <Card.Text className="cardTextContainer">
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus, impedit consequatur distinctio odio 
                                dignissimos corrupti?
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                <Col xxl={4} xl={4} lg={4} md={4} sm={12}>
                    <Card className='cardContainerAboutAPI'>
                        {/* <Card.Img variant="top" src={APIlogo} className='cardIndividualImgContainer' /> */}
                        <Card.Body>
                            <Card.Title className="cardTextTitleContainer">The benefits of {NameOfAPI}</Card.Title>
                            <Card.Text className="cardTextContainer">
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus, impedit consequatur distinctio odio 
                                dignissimos corrupti?
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        
            {/* Gallery of Images */}
            <div className="galleryOfImagesIndividualContainer">
                <Row>
                    <Col xxl={1} xl={1} lg={1} md={1} sm={10}></Col>
                    <Col xxl={10} xl={10} lg={10} md={10} sm={10}>
                        <p className='galleryIndividualTitle'>Here is how nexSembly uses {NameOfAPI} API</p>

                        {/* First Row */}
                        <div className="rowIndividualContainer">
                            <Row>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
                                    <div className="carrouselIndividual">
                                        <div className="carrouselImageContainer1">
                                            <img
                                                className="carrouselImage"
                                                src={WorkingStation1}
                                                alt="First slide"
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
                                    <div className="carrouselIndividual">
                                        <div className="carrouselImageContainer1">
                                            <img
                                                className="carrouselImage"
                                                src={WorkingStation2}
                                                alt="First slide"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        {/* Second Row */}
                        <div className="rowIndividualContainer">
                            <Row>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
                                    <div className="carrouselIndividual">
                                        <div className="carrouselImageContainer1">
                                            <img
                                                className="carrouselImage"
                                                src={WorkingStation3}
                                                alt="First slide"
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
                                    <div className="carrouselIndividual">
                                        <div className="carrouselImageContainer1">
                                            <img
                                                className="carrouselImage"
                                                src={WorkingStation2}
                                                alt="First slide"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    </div>
    )
}

export default IndividualAPI;