import './OneRawMaterials.css';
import './ThumbnailLink.css';
import BinaryCode from '../images/BinaryCode.png';
//import ScrollingArrows from './ScrollingArrows.jsx';
import VelocityThumbnail from '../images/VelocityThumbnail.png'
import ThumbnailLink from './ThumbnailLink';

const OneRawMaterials = () => {
  return (
  <>
    <div className="rawMaterialsContainer">
        {/**<ScrollingArrows/> */} 
        <div className="CaveAndNumbersContainer">
          <img src={BinaryCode} alt="" className="caveIcon"/>

          {/* Create File */}
          <div className="LineCreateFile">
            <ThumbnailLink 
              to="/CreateFile" 
              className="LinksThatUseThumbnail" 
              thumbnail={VelocityThumbnail}
            >
              Create File
            </ThumbnailLink>
          </div>

          {/* Create Lead */}
          <div className="LineCreateLead">
            <ThumbnailLink 
              to="/CreateLead" 
              className="LinksThatUseThumbnail" 
              thumbnail={VelocityThumbnail}
            >
              Create Lead
            </ThumbnailLink>
          </div>

          {/* Data Fields */}
          <div className="LineDataFields">
            <ThumbnailLink 
              to="/DataFields" 
              className="LinksThatUseThumbnail" 
              thumbnail={VelocityThumbnail}
            >
              Data Fields
            </ThumbnailLink>
          </div>
        </div>

        <div className="titleDescriptionContainer">
          <div className="titleRawMaterialsContainer">
              <p className='titleRawMaterials'><span className='boldTitles'>Raw Materials: </span><br/>Digital Data</p>
          </div>
          <div className="descriptionRawMaterialsContainer">
            <p className='descriptionRawMaterials'>
              All professional service files start by gathering personal client information and preliminary transaction details. Client identity verification is also a requirement upon opening a file.
            <br />
            <br />
              In nexSembly, large portions of the initial raw data is electronically verified, thereby ensuring accuracy throughout the process. 
            </p>
          </div>
        </div>
    </div>
  </>
  )
}

export default OneRawMaterials;