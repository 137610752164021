import './NavBar.css';
import { Link } from 'react-router-dom';
import Logo from '../images/LogoWithoutText.png'

const NavBar = () => {
    return (
        <div className='navBarContainer'>
            <Link className='buttonNavBar' to="/">Home</Link>
            <Link className='buttonNavBar' to="/AssemblyLine">Components</Link>
            <Link className='buttonNavBar' to="/APIs">APIs</Link>
            <Link className='buttonNavBar' to="/Stages">Stages</Link>
            <div className="imgWrapper">
                <Link className='buttonNavBarIMG' to="/"><img className="logoImg" src={Logo} alt="LOGO" /></Link>
            </div>
            <Link className='buttonNavBar' to="/Announcements">Announcements</Link>
            <Link className='buttonNavBar' to="/About">About Us</Link>
            <Link className='buttonNavBar' to="/Contact">Contact Us</Link>
        </div>
    )
}

export default NavBar;