import './AboutContact.css';
import NavBar from '../components/NavBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom';

const AboutContact = () => {
  const location = useLocation();
  const isContactPage = location.pathname === '/Contact';
  const contactEmail = '<a href="mailto:info@nexsembly.com" target="_blank" rel="noopener" class="contactEmail">info@nexsembly.com</a>';
  return (
    <div className='AboutContactContainer'> 
        {/* Import NavBar */}
        <NavBar/> 
        {/** AboutContact */}
          <FontAwesomeIcon icon={faCog} className="gear-icon1"/>
          <div className="AboutContactText">
              <div className="tagLineAboutContact">
                  Ingenious Digital Document Assembly
              </div> 
              <div className="titleAboutContact">
                  nexSEMBLY
              </div> 
              <div className="introductionAboutContact" dangerouslySetInnerHTML={{__html: isContactPage 
              ? `If you are interested in learning more about how nexSEMBLY can help your high volume professional services business or for a demo of the platform, please email ${contactEmail}` 
              : 'NexSEMBLY is a wholly owned software product of Nexera Legal Technologies Inc.'}}>
              </div>
              <br />              
          </div>
    </div>
  )
}

export default AboutContact;
