import '../../App.css';
import IndividualRawMaterials from '../../components/IndividualRawMaterials';
import NavBar from '../../components/NavBar';
import FirstScreenshot from '../../images/RawMaterialsImages/CreateLeadImages/FirstScreenshot.png';
import SecondScreenshot from '../../images/RawMaterialsImages//CreateLeadImages/SecondScreenshot.png';
import ThirdScreenshot from '../../images/RawMaterialsImages//CreateLeadImages/ThirdScreenshot.png';
import FourthScreenshot from '../../images/RawMaterialsImages//CreateLeadImages/FourthScreenshot.png';
import FifthScreenshot from '../../images/RawMaterialsImages//CreateLeadImages/FifthScreenshot.png';
import SixthScreenshot from '../../images/RawMaterialsImages//CreateLeadImages/SixthScreenshot.png';

const Screenshots = [
    [FirstScreenshot, SecondScreenshot],
    [ThirdScreenshot, FourthScreenshot],
    [FifthScreenshot, SixthScreenshot],
];

// Change Screenshots

const DataFields = () => {
    return (
    <div className='StagesCSSContainer'>
        {/* Import Navbar */}
        <NavBar/>
        {/* Import Individual Raw Materials page */}
        <IndividualRawMaterials
            NameOfRawMaterials={"Data Fields"}
            Screenshots={Screenshots}
        />
    </div>
    )
}

export default DataFields;