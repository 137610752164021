import React, { useEffect, useState } from 'react';
import './AssemblyLine.css';
import { Link } from 'react-router-dom';
import OneRawMaterials from '../components/OneRawMaterials';
// import TwoProspecting from '../components/TwoProspecting';
import ThreeInterfaces from '../components/ThreeInterfaces';
import FourConveyerBelts from '../components/FourConveyerBelts';
import FiveHumanInteraction from '../components/FiveHumanInteraction';
import SixQualityAssurance from '../components/SixQualityAssurance';
import SevenNexSembly from '../components/SevenNexSembly';
import { ReactComponent as BinaryDocument } from '../images/binaryDocument.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faGear, faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';

const AssemblyLine = () => {
  useEffect(() => {
    const handleWheelScroll = (event) => {
      event.preventDefault();
      document.documentElement.scrollLeft += event.deltaY;
    };
    window.addEventListener('wheel', (e) => {
      e.preventDefault();
      const delta = Math.max(-1, Math.min(1, (e.deltaY || -e.detail)));
      const scrollSpeed = 400;
      window.scrollBy({
        left: delta * scrollSpeed,
        behavior: 'smooth'
      });
    });
    return () => {
      window.removeEventListener('wheel', handleWheelScroll);
    };
  }, []);

  // eslint-disable-next-line
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = (scrollOffset) => {
    const newPosition = document.documentElement.scrollLeft + scrollOffset;
    const maxPosition = document.documentElement.scrollWidth - window.innerWidth;
    const newScrollPosition = Math.max(0, Math.min(newPosition, maxPosition));
    setScrollPosition(newScrollPosition);
    window.scrollTo({
      left: newScrollPosition,
      behavior: 'smooth'
    });
  };

  return (
    <div className='ContainerAssemblyLine'>
      {/** Home Button */}
      <div className="homeButtonContainer">
        <Link to="/" className='iconHomePageButton'><FontAwesomeIcon className='iconHomePage' icon={faHouse} size="2x"></FontAwesomeIcon></Link>      
      </div>
      
      {/** Conveyer Belt and Gears */}
      <div className="conveyerBelt">
        <FontAwesomeIcon icon={faGear} className="gear"/>
        <FontAwesomeIcon icon={faGear} className="gear"/>
        <FontAwesomeIcon icon={faGear} className="gear"/>
        <FontAwesomeIcon icon={faGear} className="gear"/>
        <FontAwesomeIcon icon={faGear} className="gear"/>
        <FontAwesomeIcon icon={faGear} className="gear"/>
        <FontAwesomeIcon icon={faGear} className="gear"/>
        <FontAwesomeIcon icon={faGear} className="gear"/>
        <FontAwesomeIcon icon={faGear} className="gear"/>
        <FontAwesomeIcon icon={faGear} className="gear"/>
      </div>
      
      <BinaryDocument className='binaryDocument'/>

      {/** Raw Materials, First Page */}
      <OneRawMaterials/>
      <div className='buttonsArrowContainer1'>
        <button className='buttonArrowRight' onClick={() => handleScroll(1500)}><FontAwesomeIcon size='3x' className='ArrowIcon' icon={faArrowAltCircleRight}/></button>
      </div>

      {/** Prospecting, Second Page 
      <TwoProspecting/>
      <div className='buttonsArrowContainer2'>
        <button className='buttonArrowLeft' onClick={() => handleScroll(-1500)}><FontAwesomeIcon size='3x' className='ArrowIcon' icon={faArrowAltCircleLeft}/></button>
        <button className='buttonArrowRight' onClick={() => handleScroll(1500)}><FontAwesomeIcon size='3x' className='ArrowIcon' icon={faArrowAltCircleRight}/></button>
      </div>*/}

      {/** Interfaces, Third Page */}
      <ThreeInterfaces/>
      <div className='buttonsArrowContainer2'>
        <button className='buttonArrowLeft' onClick={() => handleScroll(-1500)}><FontAwesomeIcon size='3x' className='ArrowIcon' icon={faArrowAltCircleLeft}/></button>
        <button className='buttonArrowRight' onClick={() => handleScroll(1500)}><FontAwesomeIcon size='3x' className='ArrowIcon' icon={faArrowAltCircleRight}/></button>
      </div>

      {/** Our Conveyer Belts, Four Page */}
      <FourConveyerBelts/>
      <div className='buttonsArrowContainer3'>
        <button className='buttonArrowLeft' onClick={() => handleScroll(-1500)}><FontAwesomeIcon size='3x' className='ArrowIcon' icon={faArrowAltCircleLeft}/></button>
        <button className='buttonArrowRight' onClick={() => handleScroll(1500)}><FontAwesomeIcon size='3x' className='ArrowIcon' icon={faArrowAltCircleRight}/></button>
      </div>
      
      {/** Human Interaction, Five Page */}
      <FiveHumanInteraction/>
      <div className='buttonsArrowContainer4'>
        <button className='buttonArrowLeft' onClick={() => handleScroll(-1500)}><FontAwesomeIcon size='3x' className='ArrowIcon' icon={faArrowAltCircleLeft}/></button>
        <button className='buttonArrowRight' onClick={() => handleScroll(1500)}><FontAwesomeIcon size='3x' className='ArrowIcon' icon={faArrowAltCircleRight}/></button>
      </div>

      {/** Quality Assurance, Six Page */}
      <SixQualityAssurance/>
      <div className='buttonsArrowContainer5'>
        <button className='buttonArrowLeft' onClick={() => handleScroll(-1500)}><FontAwesomeIcon size='3x' className='ArrowIcon' icon={faArrowAltCircleLeft}/></button>
        <button className='buttonArrowRight' onClick={() => handleScroll(1500)}><FontAwesomeIcon size='3x' className='ArrowIcon' icon={faArrowAltCircleRight}/></button>
      </div>

      {/** NexSembly, Seventh Page */}
      <SevenNexSembly/>
      <div className='buttonsArrowContainer6'>
        <button className='buttonArrowLeft' onClick={() => handleScroll(-1500)}><FontAwesomeIcon size='3x' className='ArrowIcon' icon={faArrowAltCircleLeft}/></button>
      </div>

    </div>
  );
};

export default AssemblyLine;
