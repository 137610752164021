import './ChicagoTitle.css';
import IndividualAPI from '../../components/IndividualAPI';
import NavBar from '../../components/NavBar';
import ChicagoTitleLogo from '../../images/ChicagoTitleLogo.jpg'

const ChicagoTitle = () => {
    return (
    <div className='ChicagoTitleContainer'>
        {/* Import Navbar */}
        <NavBar/>
        {/* Import Individual API page */}
        <IndividualAPI 
            NameOfAPI={"Chicago Title"}
            APIlogo={ChicagoTitleLogo}
        />
    </div>
    )
}

export default ChicagoTitle;