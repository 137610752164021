import { Link } from 'react-router-dom';
import { useState } from 'react';

export const ThumbnailLink = (image) => {
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const renderThumbnail = () => {
        if (image.id === 'thumbnailStages') {
            return (
            <img src={image.thumbnail} alt={`${image.to} thumbnail`} className="thumbnailStages" />
            );
        } else if (image.id === 'thumbnailStages2') {
            return (
            <img src={image.thumbnail} alt={`${image.to} thumbnail`} className="thumbnailStages2" />
            );
        } else if (image.id === 'thumbnailStages3') {
            return (
            <img src={image.thumbnail} alt={`${image.to} thumbnail`} className="thumbnailStages3" />
            );
        } else {
            return (
            <img src={image.thumbnail} alt={`${image.to} thumbnail`} className="thumbnail" />
            );
        }
    };

    return (
        <Link {...image} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {isHovering && renderThumbnail()}
            {image.children}
        </Link>
    );
}

export default ThumbnailLink;