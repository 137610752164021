import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react';

function RotatingGearsHome1() {
    useEffect(() => {
        const gear1 = document.querySelector('.gearIcon1');
        if (gear1) {
            const handleScroll = () => {
            const scrollTop = window.scrollY;
            const gearRotation = `rotate(${scrollTop / 10}deg)`;
            gear1.style.transform = gearRotation;
            };
            window.addEventListener('scroll', handleScroll);
            return () => {
            window.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    return (
        <div className="movingGear1">
            <FontAwesomeIcon icon={faCog} className="gearIcon1"/>
        </div>
    );
}

export default RotatingGearsHome1;