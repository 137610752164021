import './IndividualRawMaterials.css';
import { Container, Row, Col, Card } from 'react-bootstrap';

const IndividualRawMaterials = ( { NameOfRawMaterials, Screenshots  } ) => {
    return (
    <div className='IndividualRawMaterialsContainer'>
        <Container>
            <p className='nameOfIndividualRawMaterials'>{NameOfRawMaterials} </p>
            <Row>
                <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
                    <Card className='cardContainerAboutRawMaterials'>
                        <Card.Body>
                            <Card.Title className="cardTextTitleRawMaterialsContainer"> About {NameOfRawMaterials}</Card.Title>
                            <Card.Text className="cardTextRawMaterialsContainer">
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus, impedit consequatur distinctio odio 
                                dignissimos corrupti?
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
                    <Card className='cardContainerAboutRawMaterials'>
                        <Card.Body>
                            <Card.Title className="cardTextTitleRawMaterialsContainer">The benefits of {NameOfRawMaterials}</Card.Title>
                            <Card.Text className="cardTextRawMaterialsContainer">
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus, impedit consequatur distinctio odio 
                                dignissimos corrupti?
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        
            {/* Gallery of Images */}
            <div className="galleryOfImagesIndividualRawMaterialsContainer">
                <Row>
                    <Col xxl={1} xl={1} lg={1} md={1} sm={10}></Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
                        <p className='galleryIndividualRawMaterialsTitle'>File Screenshots</p>
                        {Screenshots.map((Screenshot, index) => (

                            <div className="rowIndividualRawMaterialsContainer" key={index}>
                                <Row>
                                    {Screenshot.map((image, imageIndex) => (
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} key={imageIndex}>
                                            <div className="carrouselImageRawMaterialsContainer">
                                                <img
                                                    className="carrouselImageRawMaterials"
                                                    src={image}
                                                    alt={`Slide ${imageIndex + 1}`}
                                                />
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        ))}
                        
                    </Col>
                </Row>
            </div>
        </Container>
    </div>
    )
}

export default IndividualRawMaterials;