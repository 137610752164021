import './NexOne.css';
import IndividualAPI from '../../components/IndividualAPI';
import NavBar from '../../components/NavBar';
import NexOneLogo from '../../images/NexOneLogo.png'

const NexOne = () => {
    return (
    <div className='NexOneContainer'>
        {/* Import Navbar */}
        <NavBar/>
        {/* Import Individual API page */}
        <IndividualAPI 
            NameOfAPI={"NexOne"}
            APIlogo={NexOneLogo}
        />
    </div>
    )
}

export default NexOne;