import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';

// Pages
import Home from './pages/Home';
import AssemblyLine from './pages/AssemblyLine';
import AboutContact from './pages/AboutContact';
import Announcements from './pages/Announcements';
import MenuAPIs from './pages/MenuAPIs';
import MenuStages from './pages/MenuStages';

// Raw Materials
import CreateLead from './pages/raw-materials/CreateLead';
import CreateFile from './pages/raw-materials/CreateFile';
import DataFields from './pages/raw-materials/DataFields';

// APIs
import MortgageAutomator from './pages/apis/MortgageAutomator';
import Velocity from './pages/apis/Velocity';
import NexOne from './pages/apis/NexOne';
import Paybilt from './pages/apis/Paybilt';
import ChicagoTitle from './pages/apis/ChicagoTitle';
import EIDMe from './pages/apis/EIDMe';
import TeraView from './pages/apis/TeraView';

// Stages
import PreFile from './pages/stages/PreFile';
import FileOpening from './pages/stages/FileOpening';
import NecessaryInfo from './pages/stages/NecessaryInfo';
import FileReview from './pages/stages/FileReview';
import DocumentProduction from './pages/stages/DocumentProduction';
import DocumentSigning from './pages/stages/DocumentSigning';
import PostSigning from './pages/stages/PostSigning';
import Closing from './pages/stages/Closing';
import Reporting from './pages/stages/Reporting';

// Tasks
import AllTasks from './pages/AllTasks';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path='/AssemblyLine' element={<AssemblyLine/>}/>
          <Route path="/About" element={<AboutContact/>} />
          <Route path="/Contact" element={<AboutContact/>} />
          <Route path="/Announcements" element={<Announcements/>} />

          {/* Raw Materials */}
          <Route path="/CreateLead" element={<CreateLead />} />
          <Route path="/CreateFile" element={<CreateFile />} />
          <Route path="/DataFields" element={<DataFields />} />

          {/* APIs */}
          <Route path="/APIs" element={<MenuAPIs/>} />
          <Route path="/VelocityAPI" element={<Velocity/>} />
          <Route path="/MortgageAutomatorAPI" element={<MortgageAutomator/>} />
          <Route path="/NexOneAPI" element={<NexOne/>} />
          <Route path="/PaybiltAPI" element={<Paybilt/>} />
          <Route path="/ChicagoTitleAPI" element={<ChicagoTitle/>} />
          <Route path="/EIDMeAPI" element={<EIDMe/>} />
          <Route path="/TeraViewAPI" element={<TeraView/>} />

          {/* Stages */}
          <Route path="/Stages" element={<MenuStages/>} />
          <Route path="/PreFile" element={<PreFile/>} />
          <Route path="/FileOpening" element={<FileOpening/>} />
          <Route path="/NecessaryInfo" element={<NecessaryInfo/>} />
          <Route path="/FileReview" element={<FileReview/>} />
          <Route path="/DocumentProduction" element={<DocumentProduction/>} />
          <Route path="/DocumentSigning" element={<DocumentSigning/>} />
          <Route path="/PostSigning" element={<PostSigning/>} />
          <Route path="/Closing" element={<Closing/>} />
          <Route path="/Reporting" element={<Reporting/>} />

          {/* Tasks */}
          <Route path="/Tasks" element={<AllTasks/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
