import './MenuAPIs.css';
import NavBar from '../components/NavBar';
import '../components/ThumbnailLink.css';
import ThumbnailLink from '../components/ThumbnailLink';
import API from '../images/API.png';
import VelocityThumbnail from '../images/VelocityThumbnail.png';
import MortgageAutomatorThumbnail from '../images/MortgageAutomatorThumbnail.png';
import NexOneThumbnail from '../images/NexOneThumbnail.png';
import PaybiltThumbnail from '../images/PaybiltThumbnail.png';
import eIDMeThumbnail from '../images/eIDMEThumbnail.png';
import ChicagoTitleThumbnail from '../images/ChicagoTitleThumbnail.png';

const MenuAPIs = () => {
    return (
    <div className="MenuAPIContainer">
        {/* Import NavBar */}
        <NavBar/>
        <div className="menuAPIContainer">
            <div className="menuAPITitleContainer">
                <p className='menuAPITitle'>Welcome to nexSembly APIs</p>
            </div>
            <div className="menuImageLinesContainer">
                
                <img src={API} alt="" className="menuAPIIconJunctions" />
        
                {/* Velocity API */}
                <div className="LineVelocity">
                    <ThumbnailLink 
                        to="/VelocityAPI" 
                        className="LinksThatUseThumbnail" 
                        thumbnail={VelocityThumbnail}
                    >
                    Velocity
                    </ThumbnailLink>
                </div>
        
              {/* Mortgage Automator API */}
                <div className="LineMortgageAutomator">
                    <ThumbnailLink
                        to="/MortgageAutomatorAPI"
                        className="LinksThatUseThumbnail"
                        thumbnail={MortgageAutomatorThumbnail}
                    >
                    Mortgage Automator
                    </ThumbnailLink>
                </div>
        
                {/* NexOne API */}
                <div className="LineNexOne">
                    <ThumbnailLink
                        to="/NexOneAPI"
                        className="LinksThatUseThumbnail"
                        thumbnail={NexOneThumbnail}
                    >
                    NexOne
                    </ThumbnailLink>
                </div>
        
                {/* Payblit API */}
                <div className="LinePaybilt">
                    <ThumbnailLink
                        to="/PaybiltAPI"
                        className="LinksThatUseThumbnail"
                        thumbnail={PaybiltThumbnail}
                    >
                    Paybilt
                    </ThumbnailLink>
                </div>
        
                {/* eID-Me API */}
                <div className="LineeIDMe">
                    <ThumbnailLink
                        to="/eIDMeAPI"
                        className="LinksThatUseThumbnail"
                        thumbnail={eIDMeThumbnail}
                        id="thumbnailStages3"
                    >
                    eID-Me
                    </ThumbnailLink>
                </div>
        
                {/* ChicagoTitle API */}
                <div className="LineChicagoTitle">
                    <ThumbnailLink
                        to="/ChicagoTitleAPI"
                        className="LinksThatUseThumbnail"
                        thumbnail={ChicagoTitleThumbnail}
                        id="thumbnailStages3"
                    >
                    Chicago Title
                    </ThumbnailLink>
                </div>
            </div>
        </div>
    </div>
    )
}

export default MenuAPIs;