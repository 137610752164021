import './MenuStages.css';
import NavBar from '../components/NavBar';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';

const MenuStages = () => {
    return (
    <div className="MenuStagesContainer">
        {/* Import NavBar */}
        <NavBar/>
        
        <div className="menuStagesContainer">
            {/* Title */}
            <div className='menuStagesTitleContainer'>
                <p className='menuStagesTitle'>
                    Welcome to nexSemblys' Conveyer Belt Stages.
                </p>
            </div>

            {/* Logos */}
            <div className="menuStagesLogosContainer">
                <Container>
                    <Row>

                        {/* First Column */}
                        <Col xxl={4} xl={4} lg={4} md={4} sm={12}>

                            {/* Pre-File */}
                            <Link to="/PreFile" className='menuStagesLinksCards'>
                                <Card border="info" style={{ width: '25rem', marginBottom: '3vh', backgroundColor: '#102B3F', display: 'flex', alignItems: 'center' }}>
                                    <Card.Body>
                                        <Card.Title style={{ fontSize: '3vh', marginTop: '2vh' }}>1. Pre-File Stage</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>

                            {/* File Opening */}
                            <Link to="/FileOpening" className='menuStagesLinksCards'>
                                <Card border="info" style={{ width: '25rem', marginBottom: '3vh', backgroundColor: '#102B3F', display: 'flex', alignItems: 'center' }}>
                                    <Card.Body>
                                        <Card.Title style={{ fontSize: '3vh', marginTop: '2vh' }}>2. File Opening Stage</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>

                            {/* Necessary Info */}
                            <Link to="/NecessaryInfo" className='menuStagesLinksCards'>
                                <Card border="info" style={{ width: '25rem', marginBottom: '3vh', backgroundColor: '#102B3F', display: 'flex', alignItems: 'center' }}>
                                    <Card.Body>
                                        <Card.Title style={{ fontSize: '3vh', marginTop: '2vh' }}>3. Necessary Info Stage</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>

                        {/* Second Column */}
                        <Col xxl={4} xl={4} lg={4} md={4} sm={12}>

                            {/* File Review */}
                            <Link to="/FileReview" className='menuStagesLinksCards'>
                                <Card border="info" style={{ width: '25rem', marginBottom: '3vh', backgroundColor: '#102B3F', display: 'flex', alignItems: 'center' }}>
                                    <Card.Body>
                                        <Card.Title style={{ fontSize: '3vh', marginTop: '2vh' }}>4. File Review Stage</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>

                            {/* Document Production */}
                            <Link to="/DocumentProduction" className='menuStagesLinksCards'>
                                <Card border="info" style={{ width: '25rem', marginBottom: '3vh', backgroundColor: '#102B3F', display: 'flex', alignItems: 'center' }}>
                                    <Card.Body>
                                        <Card.Title style={{ fontSize: '3vh', marginTop: '2vh' }}>5. Document Production Stage</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>

                            {/* Document Signing */}
                            <Link to="/DocumentSigning" className='menuStagesLinksCards'>
                                <Card border="info" style={{ width: '25rem', marginBottom: '3vh', backgroundColor: '#102B3F', display: 'flex', alignItems: 'center' }}>
                                    <Card.Body>
                                        <Card.Title style={{ fontSize: '3vh', marginTop: '2vh' }}>6. Document Signing Stage</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>

                        {/* Third Column */}
                        <Col xxl={4} xl={4} lg={4} md={4} sm={12}>

                            {/* Post Signing */}
                            <Link to="/PostSigning" className='menuStagesLinksCards'>
                                <Card border="info" style={{ width: '25rem', marginBottom: '3vh', backgroundColor: '#102B3F', display: 'flex', alignItems: 'center' }}>
                                    <Card.Body>
                                        <Card.Title style={{ fontSize: '3vh', marginTop: '2vh' }}>7. Post Signing Stage</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>

                            {/* Closing Stage */}
                            <Link to="/Closing" className='menuStagesLinksCards'>
                                <Card border="info" style={{ width: '25rem', marginBottom: '3vh', backgroundColor: '#102B3F', display: 'flex', alignItems: 'center' }}>
                                    <Card.Body>
                                        <Card.Title style={{ fontSize: '3vh', marginTop: '2vh' }}>8. Closing Stage</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>

                            {/* Reporting Stage */}
                            <Link to="/Reporting" className='menuStagesLinksCards'>
                                <Card border="info" style={{ width: '25rem', marginBottom: '3vh', backgroundColor: '#102B3F', display: 'flex', alignItems: 'center' }}>
                                    <Card.Body>
                                        <Card.Title style={{ fontSize: '3vh', marginTop: '2vh' }}>9. Reporting Stage</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    </div>
    )
}

export default MenuStages;