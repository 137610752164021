import './TeraView.css';
import IndividualAPI from '../../components/IndividualAPI';
import NavBar from '../../components/NavBar';
import TeraViewLogo from '../../images/TeraViewLogo.png'

const TeraView = () => {
    return (
    <div className='TeraViewContainer'>
        {/* Import Navbar */}
        <NavBar/>
        {/* Import Individual API page */}
        <IndividualAPI 
            NameOfAPI={"Teraview"}
            APIlogo={TeraViewLogo}
        />
    </div>
    )
}

export default TeraView;