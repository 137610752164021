import './Velocity.css';
import IndividualAPI from '../../components/IndividualAPI';
import NavBar from '../../components/NavBar';
import VelocityLogo from '../../images/VelocityLogo.png'

const Velocity = () => {
    return (
    <div className='VelocityContainer'>
        {/* Import Navbar */}
        <NavBar/>
        {/* Import Individual API page */}
        <IndividualAPI 
            NameOfAPI={"Velocity"}
            APIlogo={VelocityLogo}
        />
    </div>
    )
}

export default Velocity;