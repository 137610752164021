import '../../App.css';
import IndividualRawMaterials from '../../components/IndividualRawMaterials';
import NavBar from '../../components/NavBar';
import FirstScreenshot from '../../images/RawMaterialsImages/CreateFileImages/FirstScreenshot.png';
import SecondScreenshot from '../../images/RawMaterialsImages//CreateFileImages/SecondScreenshot.png';
import ThirdScreenshot from '../../images/RawMaterialsImages//CreateFileImages/ThirdScreenshot.png';
import FourthScreenshot from '../../images/RawMaterialsImages//CreateFileImages/FourthScreenshot.png';
import FifthScreenshot from '../../images/RawMaterialsImages//CreateFileImages/FifthScreenshot.png';
import SixthScreenshot from '../../images/RawMaterialsImages//CreateFileImages/SixthScreenshot.png';

const Screenshots = [
    [FirstScreenshot, SecondScreenshot],
    [ThirdScreenshot, FourthScreenshot],
    [FifthScreenshot, SixthScreenshot],
];

const CreateFile = () => {
    return (
    <div className='StagesCSSContainer'>
        {/* Import Navbar */}
        <NavBar/>
        {/* Import Individual Raw Materials page */}
        <IndividualRawMaterials
            NameOfRawMaterials={"Create File"}
            Screenshots={Screenshots}
        />
    </div>
    )
}

export default CreateFile;