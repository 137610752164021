import "./Home.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import NavBar from '../components/NavBar';
import Footer from "../components/Footer";
import RotatingGearsHome1 from "../components/RotatingGearsHome1";
import RotatingGearsHome2 from "../components/RotatingGearsHome2";
import RotatingGearsHome3 from "../components/RotatingGearsHome3";
import 'animate.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Home = () => {
    return (
        <div className="homeContainer">
            {/* Import NavBar */}
            <NavBar/>

            {/** Section 0: HOME */}
            <div className="Section0Container">
                <FontAwesomeIcon icon={faCog} className="gear-icon0"/>
                <div className="homeText">
                    <div className="tagLineHome">
                        Canada’s Real Estate Digital Superhighway
                    </div> 
                    <div className="titleHome">
                        <span className="boldTitles">nex-i-Bahn</span>
                    </div> 
                    <div className="introductionHome">
                        A customizable software platform that facilitates high volume residential real estate transactions in a 
                        collaborative, automated, and highly efficient manner.
                    </div>
                    <br />
                    <Link className="homeButtonToAssemblyLine" to="/AssemblyLine">Learn More</Link>
                </div>
            </div>

                {/** Section 1: SMART */}
                <Container>
                    <div className="smartContainer">
                        <Row>
                            <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
                                <div className="softwareTitleGearContainerHome">
                                    <div className="bigTitleHomeContainer">
                                        <RotatingGearsHome1/>
                                        
                                            <p className="bigTitleHome">SMART</p>
                                        
                                    </div>
                                </div>
                            </Col>
                            <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
                                <div className="paragraphFeaturesContainer">
                                    <p className="paragraphFeaturesHome">
                                        Drawing inspiration from the workings of a physical manufacturing assembly line, nexSEMBLY uses digital data, conveyer belts, 
                                        stage queues and smart tasks to complete a file from open to close.
                                    </p>
                                </div>  
                            </Col>
                        </Row>
                    </div>
                

                    {/** Section 2: CONNECTED */}
                    <div className="smartContainer2">
                        <Row>
                            <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
                                <div className="paragraphFeaturesContainer2">
                                    <p className="paragraphFeaturesHome2">
                                        Application Interface Protocols (API’s) provide nexSembly with access to external data. These digital interfaces 
                                        allow for relevant client and transaction information to be inputted into the platform with no manual data entry.
                                    </p>
                                </div>
                            </Col>
                            <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
                                <div className="softwareTitleGearContainerHome2">
                                    <div className="bigTitleHomeContainer2">
                                        <RotatingGearsHome2/>
                                        <p className="bigTitleHome2">CONNECTED</p>
                                    </div>
                                </div>  
                            </Col>
                        </Row>
                    </div>

                    {/** Section 3: PROVEN */}
                    <div className="smartContainer3">
                        <Row>
                            <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
                                <div className="softwareTitleGearContainerHome3">
                                    <div className="bigTitleHomeContainer3">
                                        <RotatingGearsHome3/>
                                        <p className="bigTitleHome3">PROVEN</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
                                <div className="paragraphFeaturesContainer3">
                                    <p className="paragraphFeaturesHome3">
                                        With over ten years in production, and over 50, 000 successfully completed transactions, nexSEMBLY has proven that it can scale to handle high volume.
                                    </p>
                                </div>  
                            </Col>
                        </Row>
                    </div>

                </Container>
            <Footer/>
        </div>
    )
}

export default Home;