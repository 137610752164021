import './Announcements.css';
import NavBar from '../components/NavBar';
import WorkStation1 from '../images/NewsRight1.jpg'
import WorkStation2 from '../images/NewsRight2.jpg'
import WorkStation3 from '../images/NewsRight3.jpg'
import WorkStation4 from '../images/NewsLeft1.jpg'
import { Container, Row, Col, Card } from 'react-bootstrap';

const Announcements = () => {
    return (
    <div className='AnnouncementsContainer'>
        <Container>
            {/* Import NavBar */}
            <NavBar/>

            <div className="announcementsContainer">
                <Row>
                <p className='announcementsTitle'>Interested in the latest updates, news, or anything else? Read about nexSembly in this section. (Placeholder text)</p> 
                    
                    {/* Column 1 */}
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12}>
                        <Card border="info" style={{ width: '25rem', marginBottom: '3vh'  }}>
                            <Card.Img variant="top" src={WorkStation1} />
                            <Card.Body>
                                <Card.Title>Title of the news</Card.Title>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat dicta numquam natus dolorem aperiam quibusdam 
                                    odit atque voluptatum praesentium, eos perferendis qui, pariatur cumque, consequuntur quos quod. Esse, ipsa accusamus?
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card border="info" style={{ width: '25rem', marginBottom: '3vh' }}>
                            <Card.Img variant="top" src={WorkStation2} />
                            <Card.Body>
                                <Card.Title>Title of the news</Card.Title>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat dicta numquam natus dolorem aperiam quibusdam 
                                    odit atque voluptatum praesentium, eos perferendis qui, pariatur cumque, consequuntur quos quod. Esse, ipsa accusamus?
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam, iusto nihil autem suscipit aperiam molestias 
                                    accusantium alias quo, quas quaerat sint obcaecati voluptatem error deleniti. In nam quod molestias recusandae.
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card border="info" style={{ width: '25rem', marginBottom: '3vh'  }}>
                            <Card.Img variant="top" src={WorkStation3} />
                            <Card.Body>
                                <Card.Title>Title of the news</Card.Title>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat dicta numquam natus dolorem aperiam quibusdam 
                                    odit atque voluptatum praesentium, eos perferendis qui, pariatur cumque, consequuntur quos quod. Esse, ipsa accusamus?
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* Column 2 */}
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12}>
                        <Card border="info" style={{ width: '25rem', marginBottom: '3vh'  }}>
                            <Card.Img variant="top" src={WorkStation4} />
                            <Card.Body>
                                <Card.Title>Title of the news</Card.Title>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat dicta numquam natus dolorem aperiam quibusdam 
                                    odit atque voluptatum praesentium, eos perferendis qui, pariatur cumque, consequuntur quos quod. Esse, ipsa accusamus?
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card border="info" style={{ width: '25rem', marginBottom: '3vh' }}>
                            <Card.Img variant="top" src={WorkStation1} />
                            <Card.Body>
                                <Card.Title>Title of the news</Card.Title>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat dicta numquam natus dolorem aperiam quibusdam 
                                    odit atque voluptatum praesentium, eos perferendis qui, pariatur cumque, consequuntur quos quod.
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card border="info" style={{ width: '25rem', marginBottom: '3vh'  }}>
                            <Card.Img variant="top" src={WorkStation2} />
                            <Card.Body>
                                <Card.Title>Title of the news</Card.Title>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat dicta numquam natus dolorem aperiam quibusdam 
                                    odit atque voluptatum praesentium, eos perferendis qui, pariatur cumque, consequuntur quos quod. Esse, ipsa accusamus?
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam, iusto nihil autem suscipit aperiam molestias 
                                    accusantium alias quo, quas quaerat sint obcaecati voluptatem error deleniti. In nam quod molestias recusandae.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* Column 3 */}
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12}>
                        <Card border="info" style={{ width: '25rem', marginBottom: '3vh'  }}>
                            <Card.Img variant="top" src={WorkStation3} />
                            <Card.Body>
                                <Card.Title>Title of the news</Card.Title>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat dicta numquam natus dolorem aperiam quibusdam 
                                    odit atque voluptatum praesentium, eos perferendis qui, pariatur cumque, consequuntur quos quod. Esse, ipsa accusamus?
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam, iusto nihil autem suscipit aperiam molestias 
                                    accusantium alias quo, quas quaerat sint obcaecati voluptatem error deleniti. In nam quod molestias recusandae.
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card border="info" style={{ width: '25rem', marginBottom: '3vh' }}>
                            <Card.Img variant="top" src={WorkStation4} />
                            <Card.Body>
                                <Card.Title>Title of the news</Card.Title>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat dicta numquam natus dolorem aperiam quibusdam 
                                    odit atque voluptatum praesentium, eos perferendis qui, pariatur cumque, consequuntur quos quod. Esse, ipsa accusamus?
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card border="info" style={{ width: '25rem', marginBottom: '3vh'  }}>
                            <Card.Img variant="top" src={WorkStation1} />
                            <Card.Body>
                                <Card.Title>Title of the news</Card.Title>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat dicta numquam natus dolorem aperiam quibusdam 
                                    odit atque voluptatum praesentium, eos perferendis qui, pariatur cumque, consequuntur quos quod. Esse, ipsa accusamus?
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </div>

        </Container>
    </div>
    )
}

export default Announcements;