import './AllTasks.css';
import NavBar from '../components/NavBar';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandsBound, faHourglass, faForward } from '@fortawesome/free-solid-svg-icons';

const AllTasks = () => {
    return (
    <div className="AllTasksContainer">
        {/* Import NavBar */}
        <NavBar/>
        
        <div className="allTasksContainer">
            {/* Title */}
            <div className='allTasksTitleContainer'>
                <p className='allTasksTitle'>
                    Welcome to nexSembly's different tasks
                </p>
            </div>

            {/* Logos */}
            <div className="allTasksLogosContainer">
                <Container>
                    <Row>
                        {/* Stage Bound Task */}
                        <Col xxl={4} xl={4} lg={4} md={4} sm={12}>
                            <Card border="info" style={{ width: '25rem', height: '25rem', marginBottom: '3vh', backgroundColor: '#102B3F', padding: '5%', paddingBottom: '0%' }}>
                                <Card.Header style={{ borderBottom: 'solid white 0.1em' }}><FontAwesomeIcon icon={faHandsBound} size='4x'/></Card.Header>
                                <Card.Body>
                                    <Card.Title style={{ fontSize: '3vh', marginTop: '2vh' }}>Stage Bound Task</Card.Title>
                                    <Card.Text style={{ fontSize: '2vh', marginTop: '2vh' }}>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore quasi ipsum omnis ex exercitationem necessitatibus voluptates iste in tempora dicta!
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* Stage Advance Task */}
                        <Col xxl={4} xl={4} lg={4} md={4} sm={12}>
                            <Card border="info" style={{ width: '25rem', height: '25rem', marginBottom: '3vh', backgroundColor: '#102B3F', padding: '5%', paddingBottom: '0%' }}>
                                <Card.Header style={{ borderBottom: 'solid white 0.1em' }}><FontAwesomeIcon icon={faForward} size='4x'/></Card.Header>
                                <Card.Body>
                                    <Card.Title style={{ fontSize: '3vh', marginTop: '2vh' }}>Stage Advance Task</Card.Title>
                                    <Card.Text style={{ fontSize: '2vh', marginTop: '2vh' }}>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore quasi ipsum omnis ex exercitationem necessitatibus voluptates iste in tempora dicta!
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* Time Bound Task */}
                        <Col xxl={4} xl={4} lg={4} md={4} sm={12}>
                            <Card border="info" style={{ width: '25rem', height: '25rem', marginBottom: '3vh', backgroundColor: '#102B3F', padding: '5%', paddingBottom: '0%' }}>
                                <Card.Header style={{ borderBottom: 'solid white 0.1em' }}><FontAwesomeIcon icon={faHourglass} size='4x'/></Card.Header>
                                <Card.Body>
                                    <Card.Title style={{ fontSize: '3vh', marginTop: '2vh' }}>Time Bound Task</Card.Title>
                                    <Card.Text style={{ fontSize: '2vh', marginTop: '2vh' }}>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore quasi ipsum omnis ex exercitationem necessitatibus voluptates iste in tempora dicta!
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    </div>
    )
}

export default AllTasks;