import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react';

function RotatingGearsHome3() {
    useEffect(() => {
        const gear3 = document.querySelector('.gear-icon3');
        if (gear3) {
            const handleScroll = () => {
            const scrollTop = window.scrollY;
            const gearRotation = `rotate(${scrollTop / 10}deg)`;
            gear3.style.transform = gearRotation;
            };
            window.addEventListener('scroll', handleScroll);
            return () => {
            window.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    return (
        <div className="movingGear3">
            <FontAwesomeIcon icon={faCog} className="gear-icon3"/>
        </div>
    );
}

export default RotatingGearsHome3;