import './Footer.css';
/**
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from "@fortawesome/free-brands-svg-icons"
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
*/
import Logo from '../images/CompleteLogo.png';
import { Link } from 'react-router-dom';

const Footer = () => {
return (
    <div className='FooterContainer'>
        <div className="imgWrapperFooter">
            <Link className='buttonNavBarIMG' to="/"><img className="logoImgFooter" src={Logo} alt="LOGO" /></Link>
        </div>
        <div className="creditsContainer">
            <a href="https://marcomanzor.com" target='_blank' rel="noopener noreferrer" className='anclaFooter'><p className='creditsText'>Designed and built by Marco Manzo</p></a>
        </div>
    </div>
)
}

export default Footer;
