import './EIDMe.css';
import IndividualAPI from '../../components/IndividualAPI';
import NavBar from '../../components/NavBar';
import eIDMELogo from '../../images/eIDMELogo.png'

const EIDME = () => {
    return (
    <div className='eIDMEContainer'>
        {/* Import Navbar */}
        <NavBar/>
        {/* Import Individual API page */}
        <IndividualAPI 
            NameOfAPI={"eID-Me"}
            APIlogo={eIDMELogo}
        />
    </div>
    )
}

export default EIDME;