import './SixQualityAssurance.css';
import './ThumbnailLink.css';
import QA from '../images/QA.png';
import ThumbnailLink from './ThumbnailLink';
import VelocityThumbnail from '../images/VelocityThumbnail.png'

const FiveQualityAssurance = () => {
  return (
    <div className="QualityAssuranceContainer">
    <div className="qualityAssuranceContainer">
        <div className="inspectorContainer">
          <img src={QA} className="inspectoricon" alt="" />
          
          {/* Alert Notification */}
          <div className="LineAlertNotification">
            <ThumbnailLink 
              to="/AlertNotificationAPI" 
              className="LinksThatUseThumbnail" 
              thumbnail={VelocityThumbnail}
            >
              Alert Notification
            </ThumbnailLink>
          </div>

          {/* Milestone Updates */}
          <div className="LineMilestoneUpdates">
            <ThumbnailLink 
              to="/AlertNotificationAPI" 
              className="LinksThatUseThumbnail" 
              thumbnail={VelocityThumbnail}
            >
              Milestone Updates
            </ThumbnailLink>
          </div>

          {/* Stage Notifications */}
          <div className="LineStageNotifications">
            <ThumbnailLink 
              to="/AlertNotificationAPI" 
              className="LinksThatUseThumbnail" 
              thumbnail={VelocityThumbnail}
            >
              Stage Notifications
            </ThumbnailLink>
          </div>

          {/* Field Triggers  */}
          <div className="LineFieldTriggers">
            <ThumbnailLink 
              to="/AlertNotificationAPI" 
              className="LinksThatUseThumbnail" 
              thumbnail={VelocityThumbnail}
            >
              Field Triggers 
            </ThumbnailLink>
          </div>

          {/* Document Uploads */}
          <div className="LineDocumentUploads">
            <ThumbnailLink 
              to="/AlertNotificationAPI" 
              className="LinksThatUseThumbnail" 
              thumbnail={VelocityThumbnail}
            >
              Document Uploads
            </ThumbnailLink>
          </div>

          {/* Funding States */}
          <div className="LineFundingStates">
            <ThumbnailLink 
              to="/AlertNotificationAPI" 
              className="LinksThatUseThumbnail" 
              thumbnail={VelocityThumbnail}
            >
              Funding States
            </ThumbnailLink>
          </div>

          {/* Full Reports Module */}
          <div className="LineFullReportsModule">
            <ThumbnailLink 
              to="/AlertNotificationAPI" 
              className="LinksThatUseThumbnail" 
              thumbnail={VelocityThumbnail}
            >
              Full Reports Module
            </ThumbnailLink>
          </div>

          {/* Kanban Boards */}
          <div className="LineKanbanBoards">
            <ThumbnailLink 
              to="/AlertNotificationAPI" 
              className="LinksThatUseThumbnail" 
              thumbnail={VelocityThumbnail}
            >
              Kanban Boards
            </ThumbnailLink>
          </div>

        </div>
        <div className="titleDescriptionQualityAssuranceContainer">
          <div className="titleQualityAssuranceContainer">
              <p className='titleQualityAssurance'><span className='boldTitles'>Quality Assurance: </span><br /> Nothing is Overlooked</p>
          </div>
          <div className="descriptionQualityAssuranceContainer">
            <p className='descriptionQualityAssurance'>
              High monthly transaction counts mean nothing if the process to get there is ladden with missteps and the final product is full of errors.
            <br />
            <br />
              In nexSEMBLY, no aspect of the transaction is overlooked or inmesearuble. The plaform has built in file notitifcations/alerts, customized realtime reporting and kanban boards all designed to ensure that high volume does not equate to low quailty.  
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FiveQualityAssurance