import './IndividualStages.css';
import { Container, Row, Col, Card } from 'react-bootstrap';
import WorkingStation2 from '../images/NewsRight2.jpg';
import WorkingStation3 from '../images/NewsRight3.jpg';

const IndividualStages = ( { NameOfStage, ImgStage } ) => {
    return (
    <div className='IndividualStagesContainer'>
        <Container>
            <p className='nameOfIndividualStages'>{NameOfStage} Stage</p>
            <Row>
                <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
                    <Card className='cardContainerAboutStages'>
                        <Card.Body>
                            <Card.Title className="cardTextTitleStagesContainer">About the {NameOfStage} Stage</Card.Title>
                            <Card.Text className="cardTextStagesContainer">
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus, impedit consequatur distinctio odio 
                                dignissimos corrupti?
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
                    <Card className='cardContainerAboutStages'>
                        <Card.Body>
                            <Card.Title className="cardTextTitleStagesContainer">The benefits of the {NameOfStage} Stage</Card.Title>
                            <Card.Text className="cardTextStagesContainer">
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus, impedit consequatur distinctio odio 
                                dignissimos corrupti?
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        
            {/* Gallery of Images */}
            <div className="galleryOfImagesIndividualStagesContainer">
                <Row>
                    <Col xxl={1} xl={1} lg={1} md={1} sm={10}></Col>
                    <Col xxl={10} xl={10} lg={10} md={10} sm={10}>
                        <p className='galleryIndividualStagesTitle'>File Stage Screenshots</p>

                        {/* First Row */}
                        <div className="rowIndividualStagesContainer">
                            <Row>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
                                    <div className="carrouselIndividualStages">
                                        <div className="carrouselImageStagesContainer1">
                                            <img
                                                className="carrouselImageStages"
                                                src={ImgStage}
                                                alt="First slide"
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
                                    <div className="carrouselIndividualStages">
                                        <div className="carrouselImageStagesContainer1">
                                            <img
                                                className="carrouselImageStages"
                                                src={WorkingStation2}
                                                alt="First slide"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        {/* Second Row */}
                        <div className="rowIndividualStagesContainer">
                            <Row>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
                                    <div className="carrouselIndividualStages">
                                        <div className="carrouselImageStagesContainer1">
                                            <img
                                                className="carrouselImageStages"
                                                src={WorkingStation3}
                                                alt="First slide"
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
                                    <div className="carrouselIndividualStages">
                                        <div className="carrouselImageStagesContainer1">
                                            <img
                                                className="carrouselImageStages"
                                                src={WorkingStation2}
                                                alt="First slide"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    </div>
    )
}

export default IndividualStages;