import './SevenNexSembly.css';
// import { ReactComponent as CheckedDocuments } from '../images/CheckedDocuments.svg';
import Claw from '../images/FinalComponentImage.png';

const SixNexSembly = () => {
  return (
    <div className="NexSemblyContainer">
      <div className="nexSemblyContainer">
        <div className="clawIconContainer">
          <img src={Claw} alt="" className='clawIcon'/>
        </div>
        {/**<div className="docsCheckedContainer">
          <CheckedDocuments className='docsCheckedIcon'/>
        </div>*/}

        <div className="titleDescriptionNexSemblyContainer">
          <div className="titleNexSemblyContainer">
            <p className='titleNexSembly'><span className='boldTitles'>The Final Product: </span><br /> A Successfully Closed File </p>
          </div>
        
          <div className="descriptioNexsemblyContainer">
              <p className='descriptionNexsembly'>
                High monthly transaction counts mean nothinng if the process to get there is ladden with missteps and the final product is full of errors.
              <br />
              <br />
                In nexSEMBLY, no aspect of the transaction is overlooked or inmesearuble. The plaform has built in file notitifcations/alerts, customized realtime reporting and kanban boards all designed to ensure that high volume does not equate to low quailty.  
              </p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default SixNexSembly